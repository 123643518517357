import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';

import { CoreEffects } from './store/core.effects';

@NgModule({
  imports: [EffectsModule.forFeature([CoreEffects]), CommonModule]
})
export class CoreModule {}
