import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { LOCALE_ID, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { CoreModule } from '@core/core.module';
import { AuthInterceptor } from '@core/interceptor/auth.interceptor';
import { environment } from '@env/environment';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { FormatModule } from '@shared/format/format.module';
import { ModalModule } from '@shared/modal/modal.module';
import { SpinnerModule } from '@shared/spinner/spinner.module';
import { ToastNoAnimationModule } from 'ngx-toastr';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ROOT_REDUCERS, metaReducers } from './store';
import { ToastComponent } from '@shared/toast/component/toast.component';

registerLocaleData(localeFr);

@NgModule({
  declarations: [AppComponent, ToastComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    HttpClientModule,
    CoreModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    ModalModule,
    SpinnerModule,
    FormatModule,
    ToastNoAnimationModule.forRoot({
      toastComponent: ToastComponent,
      positionClass: 'toast-bottom-center',
      maxOpened: 5,
      autoDismiss: false,
      newestOnTop: false,
      tapToDismiss: false,
      closeButton: true,
      timeOut: 4000,
      extendedTimeOut: 5000
    }),
    StoreModule.forRoot(ROOT_REDUCERS, {
      metaReducers,
      runtimeChecks: {
        strictStateSerializability: true,
        strictActionWithinNgZone: true,
        strictActionTypeUniqueness: true
      }
    }),
    EffectsModule.forRoot(),
    StoreRouterConnectingModule.forRoot(),
    environment.env === 'local' || environment.env === 'plg'
      ? StoreDevtoolsModule.instrument({
          name: 'SMG',
          maxAge: 25,
          actionsBlocklist: ['NOOP', '[Core] Show spinner', '[Core] Hide spinner']
        })
      : [],
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.env !== 'local',
      registrationStrategy: 'registerImmediately'
    })
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
