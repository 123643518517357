import { Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { filter, interval } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UpdateService {
  constructor(public updates: SwUpdate) {
    if (updates.isEnabled) {
      this.updates.activateUpdate();
      interval(60 * 60 * 1000).subscribe(() => updates.checkForUpdate());
    }
  }

  public checkForUpdates(): void {
    this.updates.versionUpdates
      .pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
      .subscribe(evt => this.promptUser());
  }

  private promptUser(): void {
    alert(`Une nouvelle mise à jour de l'application est disponible. La page va se recharger.`);
    window.location.reload();
  }
}
