<div class="relative">
  <div class="ml-8 mr-3">
    {{ message }}
  </div>
  <div *ngIf="options.closeButton"
    (click)="remove()"
    class="absolute top-0 right-0 -mt-3 -mr-3 border border-white border-solid rounded-full cursor-pointer">
    <img class="h-4"
      src="../../../../assets/icons/close.svg" />
  </div>
</div>