import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UpdateService } from '@core/services/update.service';
import { fromCore } from '@core/store';
import { datadogRum } from '@datadog/browser-rum';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {
  public showSpinner$: Observable<number>;

  constructor(
    private store: Store,
    private updateService: UpdateService
  ) {
    this.showSpinner$ = this.store.select(fromCore.getSpinner);
  }

  ngOnInit(): void {
    this.updateService.checkForUpdates();

    if (environment.env !== 'local') {
      datadogRum.init({
        applicationId: environment.datadogApplicationId,
        clientToken: environment.datadogClientToken,
        site: 'datadoghq.com',
        service: 'energies-sim',
        env: environment.env,
        version: '1.0.0',
        sessionSampleRate: 100,
        trackUserInteractions: true
      });
    }
  }
}
