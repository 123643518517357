import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Auth0Client, User, createAuth0Client } from '@auth0/auth0-spa-js';
import { BehaviorSubject, Observable, combineLatest, from, of, throwError } from 'rxjs';
import { catchError, concatMap, delay, shareReplay, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  auth0Client$ = (
    from(
      createAuth0Client({
        domain: environment.auth0Domain,
        clientId: environment.auth0ClientId,
        authorizationParams: {
          redirect_uri: window.location.origin
        }
      })
    ) as Observable<Auth0Client>
  ).pipe(
    shareReplay(1),
    catchError(err => throwError(err))
  );

  public iobaseAuth0Client$ = (
    from(
      createAuth0Client({
        domain: environment.iobaseAuth0Domain,
        clientId: environment.iobaseAuthorizerTeregaClientId,
        authorizationParams: {
          redirect_uri: window.location.origin
        }
      })
    ) as Observable<Auth0Client>
  ).pipe(
    shareReplay(1),
    catchError(err => throwError(err))
  );

  isAuthenticated$ = this.auth0Client$.pipe(
    delay(500),
    concatMap((client: Auth0Client) => from(client.isAuthenticated())),
    tap(res => (this.loggedIn = res))
  );
  handleRedirectCallback$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.handleRedirectCallback()))
  );
  private userProfileSubject$ = new BehaviorSubject<any>(null);
  userProfile$ = this.userProfileSubject$.asObservable();

  loggedIn: boolean = null;

  constructor(private router: Router) {
    this.localAuthSetup();
    this.handleAuthCallback();
  }

  getUser$(options?): Observable<User> {
    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) => from(client.getUser())),
      tap(user => this.userProfileSubject$.next(user))
    );
  }

  private localAuthSetup(): any {
    const checkAuth$ = this.isAuthenticated$.pipe(
      concatMap((loggedIn: boolean) => {
        if (loggedIn) {
          return this.getUser$();
        }
        return of(loggedIn);
      })
    );
    checkAuth$.subscribe();
  }

  public login(redirectPath: string = '/'): void {
    this.auth0Client$.subscribe((client: Auth0Client) => {
      // Call method to log in
      client.loginWithRedirect({
        //redirect_uri: `${window.location.origin}`,
        appState: { target: redirectPath }
      });
    });
  }

  private handleAuthCallback(): any {
    const params = window.location.search;
    if (params.includes('code=') && params.includes('state=')) {
      let targetRoute: string;
      const authComplete$ = this.handleRedirectCallback$.pipe(
        tap(cbRes => {
          targetRoute = cbRes.appState && cbRes.appState.target ? cbRes.appState.target : '/';
        }),
        concatMap(() => {
          return combineLatest([this.getUser$(), this.isAuthenticated$]);
        })
      );
      authComplete$.subscribe(() => {
        this.router.navigateByUrl(targetRoute);
      });
    }
  }

  public logout(): void {
    this.auth0Client$.subscribe((client: Auth0Client) => {
      client.logout({
        clientId: environment.auth0ClientId,
        logoutParams: {
          returnTo: window.location.origin
        }
      });
      localStorage.removeItem('lastSimulationPosition');
      localStorage.removeItem('simulationDate');
      localStorage.removeItem('displaySettings');
      localStorage.removeItem('user');
    });
  }

  public getToken$(audience: string, scope: string, targetClient: 'Terega' | 'Iobase' = 'Terega'): Observable<string> {
    if (targetClient === 'Terega') {
      return this.auth0Client$.pipe(
        concatMap((client: Auth0Client) => client.getTokenSilently({ authorizationParams: { audience, scope } })),
        catchError(err =>
          this.auth0Client$.pipe(
            concatMap((client: Auth0Client) => client.getTokenWithPopup({ authorizationParams: { audience, scope } }))
          )
        )
      );
    } else {
      return this.iobaseAuth0Client$.pipe(
        concatMap((client: Auth0Client) => client.getTokenSilently({ authorizationParams: { audience, scope } })),
        catchError(err =>
          this.iobaseAuth0Client$.pipe(
            concatMap((client: Auth0Client) => client.getTokenWithPopup({ authorizationParams: { audience, scope } }))
          )
        )
      );
    }
  }
}
