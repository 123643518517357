import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { fromCore } from '../store';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {
  constructor(private store: Store) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let modifiedHeaders = request.headers;
    return this.store.select(fromCore.getUser).pipe(
      switchMap(user => {
        if (request.url.includes('io-base.com')) {
          modifiedHeaders = modifiedHeaders.set('Authorization', `Bearer ${user.iobaseBearer}`);
        } else {
          modifiedHeaders = modifiedHeaders.set('Authorization', `Bearer ${user.bearer}`);
        }

        request = request.clone({ headers: modifiedHeaders });
        return next.handle(request);
      })
    );
  }
}
