import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from '@core/models/config.model';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private baseUrl: string = environment.apiUrl + '/config';

  constructor(private http: HttpClient) {}

  public getConfig(): Observable<Config> {
    return this.http.get<Config>(`${this.baseUrl}`);
  }
}
