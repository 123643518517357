import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@core/services/auth.service';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { Observable, forkJoin, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { Role, User } from '../models/user.model';
import { CoreActions } from '../store';
import { jwtDecode } from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private store: Store,
    private auth: AuthService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const userString = localStorage.getItem('user');
    let user = null;
    if (userString) {
      user = JSON.parse(userString) as User;
      if (Date.now() < user.bearerExp * 1000 || Date.now() < user.bearerIndaba * 1000) {
        this.store.dispatch(CoreActions.login({ user }));
        return of(true);
      }
    }

    return this.auth.isAuthenticated$.pipe(
      switchMap((isAuthenticated: boolean) => {
        if (isAuthenticated) {
          return forkJoin([
            this.auth.getToken$(environment.smgOAuth2Audience, environment.smgOAuth2Scopes),
            this.auth.getUser$()
          ]).pipe(
            switchMap(([smgToken, u]) => {
              return this.auth
                .getToken$(environment.iobaseIndabaOAuth2Audience, environment.smgOAuth2Scopes, 'Iobase')
                .pipe(
                  switchMap(iobasetoken => {
                    const tokenDecoded = jwtDecode(smgToken) as { permissions: Array<string>; exp: number };
                    const iobaseTokenDecoded = jwtDecode(iobasetoken) as { permissions: Array<string>; exp: number };
                    let userRole = Role.NONE;
                    tokenDecoded.permissions.forEach(p => {
                      switch (p) {
                        case 'all:simulateur':
                          userRole = userRole === Role.NONE ? Role.SIMULATEUR : userRole;
                          break;
                        case 'all:concepteur':
                          userRole =
                            userRole === Role.NONE || userRole === Role.SIMULATEUR ? Role.CONCEPTEUR : userRole;
                          break;
                        case 'all:admin':
                          userRole = userRole === Role.INTERNAL ? userRole : Role.ADMIN;
                          break;
                        case 'all:internal':
                          userRole = Role.INTERNAL;
                          break;
                      }
                    });
                    localStorage.removeItem('simulationPosition');
                    localStorage.removeItem('simulationDate');
                    localStorage.removeItem('displaySettings');

                    if (userRole === Role.NONE) {
                      localStorage.removeItem('user');
                      this.auth.logout();
                      return of(false);
                    }
                    user = {
                      email: u.email,
                      familyName: u.family_name,
                      givenName: u.given_name,
                      picture: u.picture,
                      fullName: u.family_name + ' ' + u.given_name,
                      role: userRole,
                      bearer: smgToken,
                      bearerExp: tokenDecoded.exp,
                      iobaseBearer: iobasetoken,
                      iobaseBearerExp: iobaseTokenDecoded.exp
                    } as User;
                    localStorage.setItem('user', JSON.stringify(user));
                    this.store.dispatch(CoreActions.login({ user }));
                    return of(true);
                  })
                );
            })
          );
        } else {
          this.auth.login(state.url);
          return of(false);
        }
      })
    );
  }
}
